import request from '@/utils/requestV2'
const qs = require('qs')

// 新增服务费配置项
export function addServiceItem (data) {
  return request({
    url: '/ooh-msp/v1/serviceitem/addserviceitem',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除配置服务项
export function deleteServiceItem (data) {
  return request({
    url: '/ooh-msp/v1/serviceitem/deleteserviceitem',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取工艺材质列表
export function getMaterialList (data) {
  return request({
    url: '/ooh-msp/v1/serviceitem/getmateriallist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据费用项id获取费用项信息
export function getServiceItem (data) {
  return request({
    url: '/ooh-msp/v1/serviceitem/getserviceitem',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 分页获取服务费配置项
export function getServiceItemPage (data) {
  return request({
    url: '/ooh-msp/v1/serviceitem/getserviceitempage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 更新服务费配置项
export function updateServiceItem (data) {
  return request({
    url: '/ooh-msp/v1/serviceitem/updateserviceitem',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取供应商成本配置
export function getSupplierServiceCostList (data) {
  return request({
    url: '/ooh-msp/v1/serviceitem/getsupplierservicecostlist',
    method: 'post',
    data: qs.stringify(data)
  })
}

<template>
  <div>
    <Divider
      dashed
      style="color:#fff"
    >配置供应商</Divider>
    <div class="p-b-10">
      <h4 class="workplatform-title m-t-10 m-b-20">选择配置供应商</h4>
      <div>
        <Select
          v-model="selectedCompanyId"
          size="small"
          placeholder="选择配置供应商"
          @on-change="handleChangeCompany"
        >
          <Option
            v-for="item in companyArray"
            :key="'ps_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </div>
    </div>
    <div
      class="p-b-10"
      v-if="taskType!==5&&selectedCompanyId&&taskCostTypeArray.length"
    >
      <h4 class="workplatform-title m-t-10 m-b-20">选择成本项</h4>
      <Card
        class="m-b-10"
        v-for="item in serviceCostArray"
        :key="'coatType_'+item.costType"
      >
        <p slot="title">{{item.costTypeName}}</p>
        <div v-if="item.deviceModel&&item.deviceModel.length">
          <div
            class="m-b-5"
            v-for="deviceModel in item.deviceModel"
            :key="'dm_'+item.costType+'_'+deviceModel.deviceModel"
          >
            <div class="m-b-5">
              <span>设备类型：</span>
              <Tag color="primary">{{deviceModel.deviceModelName}}</Tag>
            </div>
            <div v-if="deviceModel.serviceItemVOList&&deviceModel.serviceItemVOList.length">
              <Select
                size="small"
                :transfer="true"
                v-model="deviceModel.selectedCostItem"
              >
                <Option
                  v-for="cost in deviceModel.serviceItemVOList"
                  :key="'cost_'+item.costType+'_'+deviceModel.deviceModel+'_'+cost.id"
                  :value="item.costType+'_'+deviceModel.deviceModel+'_'+cost.id"
                >{{cost.name+'-'+cost.feeTypeName+'-'+cost.price+'元/'+cost.unitName}}</Option>
              </Select>
            </div>
          </div>
        </div>
      </Card>
    </div>
    <div class="text-right">
      <Button
        type="success"
        size="small"
        :disabled="selectedTaskItemIds.length===0"
        @click="handleSubmit"
      >确认配置</Button>
    </div>
  </div>
</template>

<script>
import { companyMixin } from '@/assets/mixins/company'
import { setLabor } from '@/api/msp/taskitem'
import { getSupplierServiceCostList } from '@/api/msp/serviceitem'
export default {
  mixins: [companyMixin],
  data () {
    return {
      type: 3, // 获取不同端类型的公司
      selectedCompanyId: null,
      serviceCostArray: [] // 服务成本项
    }
  },
  created () {
    this.getCompanyArray()
  },
  computed: {
    taskId () {
      return this.$store.state.workOrder.taskId
    },
    taskType () {
      return this.$store.state.workOrder.taskType
    },
    selectedSupplierId () {
      return this.$store.state.workOrder.selectedSupplierId
    },
    selectedTaskItemIds: {
      get () {
        return this.$store.state.workOrder.selectedTaskItemIds
      },
      set (val) {
        this.$store.commit('set_selected_task_item_ids', val)
      }
    },
    taskCostTypeArray: {
      get () {
        return this.$store.state.workOrder.taskCostTypeArray
      },
      set (val) {
        this.$store.commit('set_task_cost_type_array', val)
      }
    }
  },
  methods: {
    handleChangeCompany () {
      if (!this.selectedCompanyId) {
        this.serviceCostArray = []
        return false
      }
      if (this.taskType !== 5) { // 维修任务不执行
        this.getServiceCostData()
      }
    },
    getServiceCostData () {
      if (this.taskCostTypeArray.length === 0) {
        this.serviceCostArray = []
        return false
      }
      const query = {
        companyId: this.selectedCompanyId,
        costTypeDeviceModels: JSON.stringify(this.taskCostTypeArray)
      }
      getSupplierServiceCostList(query).then(res => {
        if (res && !res.errcode) {
          this.serviceCostArray = res
          this.serviceCostArray.forEach(item => {
            item.deviceModel.forEach(deviceModel => {
              const serviceItem = deviceModel.serviceItemVOList.find(x => x.defaultSelect)
              if (serviceItem && serviceItem.id) { // 存在默认成本项的处理
                deviceModel.selectedCostItem = `${item.costType}_${deviceModel.deviceModel}_${serviceItem.id}`
              } else {
                deviceModel.selectedCostItem = `${item.costType}_${deviceModel.deviceModel}_${deviceModel.serviceItemVOList && deviceModel.serviceItemVOList.length ? deviceModel.serviceItemVOList[0].id : 0}`
              }
            })
          })
        } else {
          this.serviceCostArray = []
        }
      })
    },
    handleSubmit () {
      if (!this.selectedCompanyId) {
        this.$Notice.warning({ desc: '请选择一个供应商' })
        return false
      }
      // 处理成本项数据
      const deviceModelCosts = []
      if (this.taskType !== 5) { // 排除维修任务
        this.serviceCostArray.forEach(element => {
          element.deviceModel.forEach(item => {
            const itemChild = item.selectedCostItem.split('_')
            const costItem = {
              costType: parseInt(itemChild[0]),
              deviceModel: parseInt(itemChild[1]),
              serviceItemId: parseInt(itemChild[2])
            }
            deviceModelCosts.push(costItem)
          })
        })
      }

      const postData = {
        companyId: this.selectedCompanyId,
        taskId: this.taskId,
        taskitemIds: JSON.stringify(this.selectedTaskItemIds),
        deviceModelCosts: JSON.stringify(deviceModelCosts)
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认配置信息无误，并提交？',
        onOk: () => {
          setLabor(postData).then(res => {
            if (res && res.errcode === 0) {
              this.selectedTaskItemIds = []
              this.$store.commit('set_temp_selected_item', null)
              this.$store.dispatch('getTaskItemData')
              this.$store.dispatch('getCompanyStatisticData')
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    }
  },
  watch: {
    taskCostTypeArray: {
      deep: true,
      immediate: true,
      handler (val) {
        if (this.selectedCompanyId && this.taskType !== 5) {
          this.getServiceCostData()
        }
      }
    },
    selectedSupplierId: {
      deep: true,
      immediate: true,
      handler (val) {
        this.selectedCompanyId = val
        this.handleChangeCompany()
      }

    }
  }
}
</script>
